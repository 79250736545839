@import '../../../../global/scss/config';

.project-card {
  @include box-shadow-2;

  @include mq-screen-lt($screen-md-min){
    width:100%;
  }

  width:48%;
  margin-bottom:36px;
  border-radius:5px;
  overflow:hidden;
  transition: all 0.2s ease-in;

  &:hover{
    cursor: pointer;
    transform:translate(0, -6px);

  }

  &:last-of-type {
    margin-right:0;
  }

  &-image {
    @include max-size;
  }
}