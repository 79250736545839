@import '../../../../global/scss/config';

.project-page {
  @include flex(column, flex-start, center);
  @include pad-box(48px 24px);
  background:white;
  width:100%;

  .content {
    display:flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;

    .title {
      @include font(60px, 600, 0, $dark-gray);
      margin-bottom:24px;
      font-family: Sarabun,sans-serif;

      @include mq-screen-lt($screen-md-min) {
        @include font(48px, 600, 0em, $primary-text-color);
      }
      @include mq-screen-lt($screen-sm-min) {
        @include font(36px, 600, 0em, $primary-text-color);
      }
    }

    .dates {
      @include font(14px, 500, 0, #7086ad);
      margin-bottom: 6px;
    }

    .description {
      @include font(14px, 500, 0, $medium-gray);
      font-family: "Open Sans", sans-serif;
      margin-bottom: 48px;
    }

    .links {
      @include flex(row, flex-start, center);
      margin-bottom:48px;

      .git{
        margin-left:24px;
        &:hover{
          cursor: pointer;
        }
      }
    }

    .embeds {
      .project-embed-container {
        @include box-shadow-2;
        margin-bottom: 48px;

      }

      .project-embed-video {
        background: black;
        border-radius: 5px;
        padding-bottom: 56.25%;
        padding-top: 25px;
        box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
      }
    }
  }


}