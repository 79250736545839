@import '../../../../global/scss/config';

.work-page{
  @include flex(row, flex-start, flex-start);
  @include max-size;
  background:$secondary-background-color;

  .work-content{
    @include max-size;


    &-header{
      @include flex(column, center, center);
      @include font(24px, 600, 0, $dark-gray);
      @include border(none none solid none);
      @include pad-box(24px);
      background:white;
      .header {
        @include flex(row, center, center);
        max-width:700px;
        width: 100%;
        background: white;
        position: relative;

        .header-content {
          @include flex(row, space-between, center);
          @include scale-max-content-width;
          .left {
            .work-name {
              @include font(36px, 700, 0, $dark-gray);
            }
          }
          .right {

          }
        }
      }
      .tabs-container{
        @include flex(row, center, center);
        @include pad-box(12px 24px);
        width:100%;
        .work-header-tabs {
          @include scale-max-content-width;
        }
      }
    }

    .status-container {
      @include pad-box(24px 24px 12px 24px);
      background:$secondary-background-color;
      .status {
        @include pad-box(24px);
        @include box-shadow-1;
        border-radius:5px;
        background:white;
      }
    }
  }

}